import './App.css';
import React from 'react';
import {  BrowserRouter as Router,  Routes,  Route} from "react-router-dom";
import Home from './Pages/Home';
import Camera from './Pages/Camera';
import Ranks from './Pages/Ranks';
import { AppRoot } from '@telegram-apps/telegram-ui';

function App() {
  return (
    <AppRoot>
      <Router>
        <Routes>
            <Route path="/camera" element={<Camera/>}>
            </Route>
            <Route path="/ranks" element={<Ranks/>}>
            </Route>
            <Route path="/" element={<Home/>}>
            </Route>
          </Routes>
      </Router>
    </AppRoot>
  );
}

export default App;