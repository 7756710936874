import React, {useEffect, useState} from "react";
import '@telegram-apps/telegram-ui/dist/styles.css';
import '../App.css';
import Maintabs from '../Components/Maintabs';
import {appSetup} from "../scripts/index";
import { useLocation, useSearchParams } from 'react-router-dom';

function Camera() {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentTaskk, setTask] = useState('task');
    useEffect(()=>{
        var task = searchParams.get("task");

        setTask(task);
        appSetup(task);
        
    }, [location, currentTaskk]);

    return (<>
        <Maintabs active={currentTaskk == 'jj' ? 3 : 4}/>
        <div id="stats"></div>
        <div id="cameraMain">
            <div className="container">
            
            <div id="video-wrapper" className="canvas-wrapper"> 
                <canvas id="output"></canvas>
                <video id="video" playsInline></video>
                <div id="repsCounter" className="meta">
                    <div class="text">0</div>
                </div>
                <div id="appLogo"><img src="static/fitmax-logo.png"/></div>
                <div id="appMessage" className="meta">
                    <span className="text">Click Play to start today task</span>
                    <span className="text-out-of-sight">Out of sight!</span>
                </div>
                <div id="appPlayButton" className="meta">
                    <img src="static/icon-play.png" id="btnPlay" className="icon"/>
                    <span className="timer">00:00</span>
                </div>
                <div id="loader">INITIALIZING...</div>
            </div>
            <div id="scatter-gl-container"></div>
            </div>
        </div>
    </>);
}

export default Camera;