import '@telegram-apps/telegram-ui/dist/styles.css';
import React, { useState, useEffect } from "react";
import { Cell, List, Subheadline, LargeTitle, InlineButtons, Avatar } from '@telegram-apps/telegram-ui';
import '../App.css';
import axios from 'axios';
import Maintabs from '../Components/Maintabs';
import { getBackendURL } from '../scripts/params.js';

function Home() {
    const [userInfo, setUserInfo] = useState(false);

    useEffect(() => {
        getUserInfo();
    }, []);

    async function getUserInfo(){
        var serverURL = getBackendURL();

        const response = await axios.post(
              serverURL,
              {
                  api: 'info',
                data: btoa(window.Telegram.WebApp.initData),
              },
              {
                  headers: {
                      'Authorization': 'Bearer ' + btoa(window.Telegram.WebApp.initData),
                  },
              }
          ).catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log("Error response data", error.response.data);
            console.log("Error response status", error.response.status);
            console.log("Error response headers", error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log("The request was made but no response was received", error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
        });
      
        if(response){
          console.log("User Info", response.data);
          if(response.data.code == 200){
            /*
            
            "username": "hadoanngoc",
        "balance": 12345,
        "todaySquat": 0,
        "todaySquatCount": 0,
        "todayJJ": 0,
        "todayJJCount": 0,
        "monthEarn": 10000,
        "monthRank": 69,
        "monthTop": 15000
            */
            setUserInfo(response.data.data);
          }
        } else {
          console.log("Unable to connect to server", response);
        }
        
      }

      return (
        <>
    <List className='home'>
      <div className='home-header'>
        <Subheadline level="2" weight="3" align="center">
          Total Balance
        </Subheadline>
        <LargeTitle align="center" weight="1">
          {Intl.NumberFormat().format(userInfo.balance)}
        </LargeTitle>
      </div>

      <Maintabs active={1}/>
      
		  <Cell className='list-item' before={<Avatar  className='icon' size={32} src='https://cryptologos.cc/logos/toncoin-ton-logo.png?v=032' />} subtitle="Exploring TON Blockchain">
			  Connect Wallet
		  </Cell>
		  <Cell className='list-item' after={<Subheadline>{userInfo.todayJJCount}</Subheadline>} before={<Avatar className='icon' size={32} src='timer.png'/>} subtitle={userInfo.todayJJ + "/1 today"}>
			  Jumping Jacks
		  </Cell>
      <Cell className='list-item' after={<Subheadline>{userInfo.todaySquatCount}</Subheadline>} before={<Avatar className='icon' size={32} src='timer.png'/>} subtitle={userInfo.todaySquat + "/1 today"}>
			  Squat
		  </Cell>
		  <Cell className='list-item' after={<Subheadline>{Intl.NumberFormat().format(userInfo.monthEarn)}</Subheadline>} before={<Avatar className='icon' size={32} src='trophy.png'/>} subtitle="Ended on 31st May">
			  Monthly Competition
		  </Cell>
      <Cell className='list-item' after={<Subheadline>{Intl.NumberFormat().format(userInfo.monthTop)}</Subheadline>} before={<Avatar className='icon' src='ribbon.png' size={32} />} subtitle={"Current rank: " + Intl.NumberFormat().format(userInfo.monthRank)}>
			  Championship
		  </Cell>
	  </List>
        </>);
}

export default Home;