import '@telegram-apps/telegram-ui/dist/styles.css';
import React, { useState, useEffect } from "react";
import { Cell, List, Subheadline, LargeTitle, InlineButtons, Avatar } from '@telegram-apps/telegram-ui';
import '../App.css';
import "../scripts/index";
import axios from 'axios';
import Maintabs from '../Components/Maintabs';
import { getBackendURL } from '../scripts/params.js';

function Ranks() {
    const [ranks, setRanks] = useState([]);
    const [value, setValue] = useState(0);

    useEffect(() => {
        getTop();
    }, []);

     async function getTop(){
        var serverURL = getBackendURL();
        
        const response = await axios.post(
              serverURL,
              {
                  api: 'top',
              },
              {
                  headers: {
                      'Authorization': 'Bearer ' + btoa(window.Telegram.WebApp.initData),
                  },
              }
          ).catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log("Error response data", error.response.data);
            console.log("Error response status", error.response.status);
            console.log("Error response headers", error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log("The request was made but no response was received", error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
        });
      
        if(response && response.data.code == 200){
            setRanks(response.data.data);
            var total = 0;
            response.data.data.forEach(element => {
              total += element.score;
            });
            setValue(total);
        } else {
          console.log("Unable to connect to Server");
        }
      }

    return (
        <>
    <List className='home'>
		<div className='home-header'>
			<Subheadline level="2" weight="3" align="center">
				This month value
			</Subheadline>
			<LargeTitle align="center" weight="1">
				{Intl.NumberFormat().format(value)}
			</LargeTitle>
		</div>
    <Maintabs active={2} />
    {ranks.map((item, idx)=>{
      var before = idx + 1;
      if(idx <= 2){
        before = <Avatar className='icon' size={32} src='ranking.png'/>;
      }
      return (<Cell className='list-item' before={before} after={item.score} subtitle="">
			{item.user}
		</Cell>);
    })
  }
		
	</List>
        </>);
}

export default Ranks;