/**
 * @license
 * Copyright 2021 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */

import "@tensorflow/tfjs-backend-webgl";
import * as mpPose from "@mediapipe/pose";
import * as tfjsWasm from "@tensorflow/tfjs-backend-wasm";
import * as posedetection from "@tensorflow-models/pose-detection";
import { TensorCamera } from "./camera";
import { STATE, BACKEND_SERVERS } from "./params";
import { setBackendAndEnvFlags } from "./util";
import { setupDatGui } from "./option_panel";
import axios from 'axios';

tfjsWasm.setWasmPaths(
  `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${tfjsWasm.version_wasm}/dist/`
);

let debugmode = 0;
let detector, camera, stats;
let startInferenceTime,
  numInferences = 0;
let inferenceTimeSum = 0,
  lastPanelUpdate = 0;
let rafId;

let lastPosition;
let totalJumps = 0;
let exerciseProgress;
let todayTask; // checking if user has completed today task (0 or 1)
let taskStatus; // 0: not started; 1: counting
let taskLimit; // number of seconds limit for the task
let taskStartTime; // timestamp when task was started
let taskToken;
let outOfSightFlag; // use to mark the timestamp of the last out-of-sight position
let visibleOfSightFlag; // use to mark the timestamp of the last visible position
let screenshot = false;
let isBodyVisible;
let exercise = 'jj';

const delay = millis => new Promise((resolve, reject) => {
  setTimeout(_ => resolve(), millis)
});

function defer() {
  if (window.jQuery) {
    
    window.jQuery(document).ready(async function($){
      appSetup();

      // Get data from server
      await getUserInfo(); 

      if(todayTask > 0){
        $('#appMessage .text').text('Today task completed. Please comeback tomorrow!');
        $('#appPlayButton').hide();
      }

      $('#appPlayButton').on('click', async function(){
        if(taskStatus == 0){
          startJumping();
        }    
      });
    });
  } else {
      setTimeout(function() { defer() }, 50);
  }
}

/**
 * 
 * @param {number} deg A number from 0 to 360 
 */
function setArc(deg){
  var segment1skew = 0, segment2skew = 0, segment3skew = 0, segment4skew = 0;
  if(deg > 270){
    // control segment4 skew
    segment4skew = 360 - deg;
  } else if(deg > 180){
    // control segment3 skew
    segment4skew = 90;

    segment3skew = 270 - deg;
  } else if(deg > 90){
    // control segment2 skew
    segment4skew = 90;
    segment3skew = 90;

    segment2skew = 180 - deg;
  } else {
    // control segment1 skew
    segment4skew = 90;
    segment3skew = 90;
    segment2skew = 90;

    segment1skew = 90 - deg;
  }

  var $ = window.jQuery;
  $('#segment2').css("transform", "rotate(0deg) skew(" + segment2skew + "deg)");
  $('#segment3').css("transform", "rotate(90deg) skew(" + segment3skew + "deg)");
  $('#segment4').css("transform", "rotate(180deg) skew(" + segment4skew + "deg)");
  $('#segment1').css("transform", "rotate(270deg) skew(" + segment1skew + "deg)");
  
}

defer();

async function startJumping(){
  var $ = window.jQuery;
  taskStatus = 1;
  totalJumps = 0;

  $('#appPlayButton').addClass('playing');
  $('#repsCounter').addClass('counting');
  $('#video-wrapper').addClass('playing');

  taskStartTime = new Date().getTime();
  var serverURL = BACKEND_SERVERS.DEV;
  if(window.location.hostname == 'botgame1.fitmax.io'){
    serverURL = BACKEND_SERVERS.PRODUCTION;
  }
  const response = await axios.post(
		serverURL,
		{
			api: 'start',
			authenData: btoa(window.Telegram.WebApp.initData),
      debug: debugmode
		},
		{
			headers: {
				'Authorization': 'Bearer ' + btoa(window.Telegram.WebApp.initData),
			},
		}
	).catch(function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log("Error response data", error.response.data);
      console.log("Error response status", error.response.status);
      console.log("Error response headers", error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("The request was made but no response was received", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
  });

	const token = typeof response !== 'undefined' ? response.data.data : '';
  taskToken = token;
}

async function complete(score, data){
  var $ = window.jQuery;
  animateCoins(score);

  // capture screenshot
  if(!screenshot){
    screenshot = camera.canvas.toDataURL("image/jpeg");
  }
  
  var serverURL = BACKEND_SERVERS.DEV;
  if(window.location.hostname == 'botgame1.fitmax.io'){
    serverURL = BACKEND_SERVERS.PRODUCTION;
  }
  const response = await axios.post(
		serverURL,
		{
			api: 'complete',
			data: btoa(window.Telegram.WebApp.initData),
      score: score,
      screenshot,
      debug: debugmode
		},
		{
			headers: {
				'Authorization': 'Bearer ' + taskToken,
			},
		}
	).catch(function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log("Error response data", error.response.data);
      console.log("Error response status", error.response.status);
      console.log("Error response headers", error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("The request was made but no response was received", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
  });
  
  const responseData = typeof response !== 'undefined' ? response.data : '';
  alert(responseData ? (typeof responseData.data !== 'undefined' ? responseData.data : responseData.message) : 'Unable to connect to server');
  console.log("Complete response", response);
}

async function getUserInfo(){
  var $ = window.jQuery;
  var serverURL = BACKEND_SERVERS.DEV;
  if(window.location.hostname == 'botgame1.fitmax.io'){
    serverURL = BACKEND_SERVERS.PRODUCTION;
  }
  const response = await axios.post(
		serverURL,
		{
			api: 'info',
      data: btoa(window.Telegram.WebApp.initData),
		},
		{
			headers: {
				'Authorization': 'Bearer ' + btoa(window.Telegram.WebApp.initData),
			},
		}
	).catch(function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log("Error response data", error.response.data);
      console.log("Error response status", error.response.status);
      console.log("Error response headers", error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("The request was made but no response was received", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
  });

  if(response){
    console.log("User Info", response.data);
    if(response.data.code == 200){
      todayTask = response.data.data.todaySquat;
    }
  } else {
    console.log("Unable to connect to server", response);
  }
  
}

function showTimer(){
  var $ = window.jQuery;
  let duration = new Date().getTime();
  duration = Math.floor((duration - taskStartTime) / 1000);
  let minute = Math.floor(duration / 60);
  let second = duration - minute * 60;
  
  $('#appPlayButton .timer').text((minute < 10 ? ('0' + minute) : minute) + ':' + (second < 10 ? ('0' + second) : second));
}

async function createDetector() {
  var $ = window.jQuery;
  switch (STATE.model) {
    case posedetection.SupportedModels.PoseNet:
      return posedetection.createDetector(STATE.model, {
        quantBytes: 4,
        architecture: "MobileNetV1",
        outputStride: 16,
        inputResolution: { width: 500, height: 500 },
        multiplier: 0.75
      });
    case posedetection.SupportedModels.BlazePose:
      const runtime = STATE.backend.split("-")[0];
      if (runtime === "mediapipe") {
        return posedetection.createDetector(STATE.model, {
          runtime,
          modelType: STATE.modelConfig.type,
          solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/pose@${mpPose.VERSION}`
        });
      } else if (runtime === "tfjs") {
        return posedetection.createDetector(STATE.model, {
          runtime,
          modelType: STATE.modelConfig.type
        });
      }
    case posedetection.SupportedModels.MoveNet:
      let modelType;
      if (STATE.modelConfig.type == "lightning") {
        modelType = posedetection.movenet.modelType.SINGLEPOSE_LIGHTNING;
      } else if (STATE.modelConfig.type == "thunder") {
        modelType = posedetection.movenet.modelType.SINGLEPOSE_THUNDER;
      } else if (STATE.modelConfig.type == "multipose") {
        modelType = posedetection.movenet.modelType.MULTIPOSE_LIGHTNING;
      }
      const modelConfig = { modelType };

      if (STATE.modelConfig.customModel !== "") {
        modelConfig.modelUrl = STATE.modelConfig.customModel;
      }
      if (STATE.modelConfig.type === "multipose") {
        modelConfig.enableTracking = STATE.modelConfig.enableTracking;
      }
      return posedetection.createDetector(STATE.model, modelConfig);
  }
}

function beginEstimatePosesStats() {
  startInferenceTime = (performance || Date).now();
}

function endEstimatePosesStats() {
  const endInferenceTime = (performance || Date).now();
  inferenceTimeSum += endInferenceTime - startInferenceTime;
  ++numInferences;

  const panelUpdateMilliseconds = 1000;
  if (endInferenceTime - lastPanelUpdate >= panelUpdateMilliseconds) {
    const averageInferenceTime = inferenceTimeSum / numInferences;
    inferenceTimeSum = 0;
    numInferences = 0;
    /*
    stats.customFpsPanel.update(
      1000.0 / averageInferenceTime,
      120 // maxValue 
    );
    */
    lastPanelUpdate = endInferenceTime;
  }
}

async function renderResult() {
  if (camera.video.readyState < 2) {
    await new Promise((resolve) => {
      camera.video.onloadeddata = () => {
        resolve(camera.video);
      };
    });
  }

  let poses = null;

  // Detector can be null if initialization failed (for example when loading
  // from a URL that does not exist).
  if (detector != null) {
    // FPS only counts the time it takes to finish estimatePoses.
    beginEstimatePosesStats();

    // Detectors can throw errors, for example when using custom URLs that
    // contain a model that doesn't provide the expected output.
    try {
      poses = await detector.estimatePoses(camera.video, {
        maxPoses: STATE.modelConfig.maxPoses,
        flipHorizontal: false
      });

      if(exercise == 'jj'){
        checkAJump(poses);
      } else {
        checkASquat(poses);
      }
    } catch (error) {
      detector.dispose();
      detector = null;
      alert(error);
    }

    endEstimatePosesStats();
  }

  camera.drawCtx(totalJumps, exerciseProgress, isBodyVisible);
  window.jQuery('#video-wrapper').addClass('loaded');

  // The null check makes sure the UI is not in the middle of changing to a
  // different model. If during model change, the result is from an old model,
  // which shouldn't be rendered.
  /**
   * do not need to drawl the skeleton
   **/
  /*if (poses && poses.length > 0 && !STATE.isModelChanged) {
    camera.drawResults(poses);
  }*/
}

function checkAJump(poses){
  var $ = window.jQuery;
  // what is pose: https://github.com/tensorflow/tfjs-models/blob/master/pose-detection/README.md#pose-estimation 
  const confidenceScore = 0.3;
  const leftAnkleIndexPoint = 15; // ankle point
  const rightAnkleIndexPoint = 16; // right ankle point
  const minDistance = 10; // shoulder = 10% screen width
  const maxDistance = 40; // shoulder = 60% screen width
  const outOfSightDelay = 500; // milli-seconds. Number of mulli seconds to delay to check if user is in or out of sight  
  const timeframeToCheckPose = 100; // milli-seconds. Check change in pose after this milli-seconds. The smaller this value is, the more checks we do, and smoothier the animation, but more loads
  const legClosePosition = 1.1; // If ankle space is less than this shoulder * legClosePosition value, it is CLOSE position
  const legOpenPosition = 1.3; // If ankle space is greater than this shoulder * legOpenPosition value, it is OPEN position

  if(poses && poses.length > 0){
    //console.log("Check a pose");
    
    if($('#repsCounter').hasClass('counting') && taskStatus == 0){
      // task was running and completed
    } else {
      let currentTimestamp = new Date().getTime();
      
      // compare legs position with shoulders
      let shoulderWidth = Math.abs(poses[0].keypoints[5].x - poses[0].keypoints[6].x);
      let ankleWidth = Math.abs(poses[0].keypoints[leftAnkleIndexPoint].x - poses[0].keypoints[rightAnkleIndexPoint].x);

      var distance = (shoulderWidth / camera.canvas.width).toFixed(2) * 100;

      // we need to delay the check using the "timeframeToCheckPose" because sometimes the pose detection returns "jumping" values which makes the view flicker
      if(currentTimestamp - lastPosition.currentTimestamp > timeframeToCheckPose){

        if((distance < minDistance || distance > maxDistance) || !(poses[0].keypoints[leftAnkleIndexPoint].score > confidenceScore && poses[0].keypoints[rightAnkleIndexPoint].score > confidenceScore)){
          // delay the check 1000ms to prevent flicker issue
          if(currentTimestamp - visibleOfSightFlag > outOfSightDelay){
            //console.log("Out of sight. Hide counter");
            $('#repsCounter').fadeOut(outOfSightDelay);
            $("#video-wrapper").addClass('outofsight');

            if((distance < minDistance || distance > maxDistance)){
              if(distance < minDistance){
                $('.text-out-of-sight').text('Please come closer');
              } else {
                $('.text-out-of-sight').text('Please move further');
              }              
            } else {
              $('.text-out-of-sight').text('Out of sight!');
            }
  
            outOfSightFlag = currentTimestamp;

            isBodyVisible = 0;
          }           

          checkEndOfTime();
          return;
        }

        // delay the check out of sight to prevent flicker issue
        if(currentTimestamp - outOfSightFlag > outOfSightDelay){
          $("#video-wrapper").removeClass('outofsight');
          isBodyVisible = 1;

          //console.log("Show counter");
          $('#repsCounter').fadeIn(100);

          visibleOfSightFlag = currentTimestamp;
        }
    
        if(ankleWidth <= shoulderWidth * legClosePosition){ 
          console.log("LEGS CLOSE");   
          if(lastPosition.jumpPosition == 1){
            // check if the position of each leg do not change, so a scam!!!
            var leftMove = Math.abs(lastPosition.leftFoot - poses[0].keypoints[leftAnkleIndexPoint].x);
            var rightMove = Math.abs(lastPosition.rightFoot - poses[0].keypoints[rightAnkleIndexPoint].x);

            if(leftMove < shoulderWidth * 0.1){
              console.log('Left Leg does not move', leftMove);
              return;
            }

            if(rightMove < shoulderWidth * 0.1){
              console.log('Right Leg does not move', rightMove);
              return;
            }
        
            // save state
            lastPosition.leftFoot = poses[0].keypoints[leftAnkleIndexPoint].x; // left_ankle: 15; right_ankle: 16 - left_wrist: 9; right_wrist: 10
            lastPosition.rightFoot = poses[0].keypoints[rightAnkleIndexPoint].x;

            // person change from open legs to straight legs, then count 1 jump
            totalJumps += 1;

            // animate the number
            $('#repsCounter .text').finish().animate({fontSize: '70px'}, 200, function(){
              $('#repsCounter .text').finish().animate({fontSize: '40px'}, 200);
            })
          }

          lastPosition.jumpPosition = 0;
        } else {
          if(ankleWidth >= shoulderWidth * legOpenPosition) {
            // Legs move from CLOSE position to OPEN position
            lastPosition.jumpPosition = 1;
            
            // save state
            lastPosition.leftFoot = poses[0].keypoints[leftAnkleIndexPoint].x; // left_ankle: 15; right_ankle: 16 - left_wrist: 9; right_wrist: 10
            lastPosition.rightFoot = poses[0].keypoints[rightAnkleIndexPoint].x;
  
            console.log("LEGS OPEN");
          }
        }
    
        lastPosition.currentTimestamp = currentTimestamp;
        checkEndOfTime();
        
        window.jQuery('#repsCounter .text').text(totalJumps);
        
      }

      calculateJJProgress(poses, ankleWidth, shoulderWidth, legOpenPosition, legClosePosition);
    }
  }
}

function checkASquat(poses){
  var $ = window.jQuery;
  // what is pose: https://github.com/tensorflow/tfjs-models/blob/master/pose-detection/README.md#pose-estimation 
  const confidenceScore = 0.6;
 
  const minDistance = 10; // shoulder = 10% screen width
  const maxDistance = 40; // shoulder = 60% screen width
  const outOfSightDelay = 500; // milli-seconds. Number of mulli seconds to delay to check if user is in or out of sight  
  const timeframeToCheckPose = 100; // milli-seconds. Check change in pose after this milli-seconds. The smaller this value is, the more checks we do, and smoothier the animation, but more loads
  const squatLow = 0.3; // how low should the body go down when doing squat. it is squatLow * leg height

  if(poses && poses.length > 0){
    //console.log("Check a pose");
    
    if($('#repsCounter').hasClass('counting') && taskStatus == 0){
      // task was running and completed
    } else {
      let currentTimestamp = new Date().getTime();

      let shoulderWidth = Math.abs(poses[0].keypoints[5].x - poses[0].keypoints[6].x);
      var distance = (shoulderWidth / camera.canvas.width).toFixed(2) * 100;

      // we need to delay the check using the "timeframeToCheckPose" because sometimes the pose detection returns "jumping" values which makes the view flicker
      if(currentTimestamp - lastPosition.currentTimestamp > timeframeToCheckPose){
        // check if Hip is within the screen        
        if((distance < minDistance || distance > maxDistance) || !(poses[0].keypoints[14].score > confidenceScore && poses[0].keypoints[16].score > confidenceScore)){
          // delay the check 1000ms to prevent flicker issue
          if(currentTimestamp - visibleOfSightFlag > outOfSightDelay){
            //console.log("Out of sight. Hide counter");
            $('#repsCounter').fadeOut(outOfSightDelay);
            $("#video-wrapper").addClass('outofsight');

            if((distance < minDistance || distance > maxDistance)){
              if(distance < minDistance){
                $('.text-out-of-sight').text('Please come closer');
              } else {
                $('.text-out-of-sight').text('Please move further');
              }              
            } else {
              $('.text-out-of-sight').text('Out of sight!');
            }
  
            outOfSightFlag = currentTimestamp;

            isBodyVisible = 0;
          }           

          checkEndOfTime();
          return;
        }

        const currentPosition = (poses[0].keypoints[5].y + poses[0].keypoints[6].y) / 2;
        const squatHeightChange = (poses[0].keypoints[16].y - poses[0].keypoints[12].y) * squatLow;

        // delay the check out of sight to prevent flicker issue
        if(currentTimestamp - outOfSightFlag > outOfSightDelay){
          $("#video-wrapper").removeClass('outofsight');
          isBodyVisible = 1;

          //console.log("Show counter");
          $('#repsCounter').fadeIn(100);

          visibleOfSightFlag = currentTimestamp;
        }
        
        // init position
        if(!lastPosition.lastPosition) {
          lastPosition.lastPosition = currentPosition;
          lastPosition.topPosition = currentPosition;
        }

        if(currentPosition >= lastPosition.lastPosition){
          // going down
          console.log("Going down");

          if(lastPosition.isUp){
            calculateSquatProgress(poses, 'down', currentPosition - lastPosition.topPosition, squatHeightChange);

            if(currentPosition - lastPosition.topPosition > squatHeightChange){
              // mark the bottom position
              lastPosition.bottomPosition = currentPosition;
              lastPosition.isUp = false;
              console.log("Full squat. Now going up");
            }
          } else {
            lastPosition.bottomPosition = currentPosition;
          }
        } else {
          // going up
          console.log("Going up");
          if(!lastPosition.isUp){
            calculateSquatProgress(poses, 'up', lastPosition.bottomPosition - currentPosition, squatHeightChange);
            if((lastPosition.bottomPosition - currentPosition) > squatHeightChange){
              console.log("Jump + 1");
              totalJumps += 1;

              // animate the number
              $('#repsCounter .text').finish().animate({fontSize: '70px'}, 200, function(){
                $('#repsCounter .text').finish().animate({fontSize: '40px'}, 200);
              })

              lastPosition.isUp = true;
            }
          } else {
            lastPosition.topPosition = currentPosition;
          }
        }

        lastPosition.lastPosition = currentPosition;
        lastPosition.currentTimestamp = currentTimestamp;
        checkEndOfTime();
        
        window.jQuery('#repsCounter .text').text(totalJumps);
        
      }

      
    }
  }
}

function calculateJJProgress(poses, ankleWidth, shoulderWidth, legOpenPosition, legClosePosition){
  if(ankleWidth <= shoulderWidth * legClosePosition){
    exerciseProgress = 0;
  } else {
    if(ankleWidth >= shoulderWidth * legOpenPosition) {
      exerciseProgress = 2 * Math.PI;
    } else {
      var minWidth = shoulderWidth * legClosePosition;
      var maxWidth = shoulderWidth * legOpenPosition;

      exerciseProgress = 2 * Math.PI * (parseFloat(ankleWidth - minWidth) / (maxWidth - minWidth));
    }
  }
}

function calculateSquatProgress(poses, direction, currentChange, squatHeightChange){
  console.log("Current change", currentChange);
  console.log("Squat Height Minimum", squatHeightChange);
  console.log("Direction", direction);

  if(currentChange > 0){
    if(currentChange > squatHeightChange){
      exerciseProgress = 2 * Math.PI;
    } else {
      exerciseProgress = 2 * Math.PI * (currentChange / squatHeightChange);
    }

    if(direction == 'up'){
      exerciseProgress = 2 * Math.PI - exerciseProgress;
    }
  }
  
  console.log("Progress (deg): ", exerciseProgress * 360 / (2 * Math.PI));
}

function checkEndOfTime(){
  var $ = window.jQuery;
  let currentTimestamp = new Date().getTime();
  if(taskStatus == 1){
    if(currentTimestamp - taskStartTime > taskLimit * 1000){
      $('#video-wrapper').removeClass('playing');
      $('#appMessage .text').text('Today task completed!');
      $('#appMessage').show();
      taskStatus = 0;
      complete(totalJumps, window.Telegram.WebApp.initData);
    }
    
    showTimer();
  }
}

async function renderPrediction() {
  await checkGuiUpdate();
  if (!STATE.isModelChanged) {
    await renderResult();
  }

  // draw on each frame move
  rafId = requestAnimationFrame(renderPrediction);
}

async function checkGuiUpdate() {
  if (STATE.isTargetFPSChanged || STATE.isSizeOptionChanged) {
    camera = await TensorCamera.setupCamera(STATE.camera);
    STATE.isTargetFPSChanged = false;
    STATE.isSizeOptionChanged = false;
  }

  if (STATE.isModelChanged || STATE.isFlagChanged || STATE.isBackendChanged) {
    STATE.isModelChanged = true;

    window.cancelAnimationFrame(rafId);

    if (detector != null) {
      detector.dispose();
    }

    if (STATE.isFlagChanged || STATE.isBackendChanged) {
      await setBackendAndEnvFlags(STATE.flags, STATE.backend);
    }

    try {
      detector = await createDetector(STATE.model);
    } catch (error) {
      detector = null;
      alert(error);
    }

    STATE.isFlagChanged = false;
    STATE.isBackendChanged = false;
    STATE.isModelChanged = false;
  }
}

export var appSetup = async (task) => {
  if(window.camera){
    console.log("App Setuped");
    return;
  }

  exercise = task;

  // Gui content will change depending on which model is in the query string.
  const urlParams = new URLSearchParams("model=movenet&type=lightning");
  //const urlParams = new URLSearchParams(window.location.search);
  if (!urlParams.has("model")) {
    alert("Cannot find model in the query string.");
    return;
  }

  await setupDatGui(urlParams);

  var video = document.getElementById('video');
  if(!video){
    return;
  }
  
  lastPosition = {
    leftFoot: 0,
    rightFoot: 0,
    jumpPosition: 0, // 0: stand straight; 1: legs open
    currentTimestamp: 0,
    // for Squat
    isUp: true,
    lastPosition: 0,
    topPosition: 0,
    bottomPosition: 0
  };

  totalJumps = 0;
  exerciseProgress = 0; // 0 to 2 * Math.PI (360 degrees)
  todayTask = 0;
  taskLimit = 10; // 3 minutes
  taskStatus = 0; 
  visibleOfSightFlag = new Date().getTime();
  outOfSightFlag = visibleOfSightFlag;
  isBodyVisible = 1;

  camera = await TensorCamera.setupCamera();

  await setBackendAndEnvFlags(STATE.flags, STATE.backend);

  detector = await createDetector();
 
  renderPrediction();
}

async function animateCoins(count){
  for(var i = 0; i < count * 2; i++){
    const s = new window.SoundEffect("34T6PkrBW3jQjhYnpdxv8qMiiuXscEMoYasigAhYRms6DWnYCKZ6dDYujNHfBWDv6o1fL1SfJbCreRwiyG1i4iKbpBKxZiMLPzbW9vMBhRaew3nBCVS1eaGF1").generate();
    addcoin(s);
    await delay(500);
  }
}

async function addcoin(s) {
  s.getAudio().play();
  const c = document.createElement("img");
  c.className = "coin";
  c.src = "/coin.png";
  c.onanimationend = function() {
    window.jQuery(this).fadeOut(300, 'linear', function(){window.jQuery(this).remove()});
  }
  window.jQuery("#video-wrapper").append(c);
}