import React from "react";
import { InlineButtons } from '@telegram-apps/telegram-ui';
import { Link } from "react-router-dom";

function Maintabs({active}){
    
    return <>
    <InlineButtons mode="plain" className="maintabs">
    <Link to="/" className="tablink"><InlineButtons.Item className={"tab " + (active == 1 ? "active" : "")} text="Profile">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 448.02 448.02" width="32" height="32">
            <g>
                <path style={{fill:'#0071CE'}} d="M200.126,161.091c-8.325-17.186-15.836-48.897-44.21-48.897c-28.373,0-48.4,23.367,4.174,67.595   L200.126,161.091z"/>
                <path style={{fill:'#454F58'}} d="M437.361,295.545c0-35.48-28.762-64.242-64.242-64.242c-34.559,0-113.951-23.638-155.737-65.423   c-41.786-41.786-64.768,4.357-91.928,4.357c-27.161,0-50.142-20.985-85.661-20.985c-35.518,0-34.473,51.279-34.473,92.02   c0,40.741,0,54.273,0,54.273H437.361z"/>
                <path style={{fill:'#C3C6C8'}} d="M217.382,165.88c-41.786-41.786-64.768,4.357-91.928,4.357c-27.161,0-50.142-20.985-85.661-20.985   c-27.653,0-33.142,31.085-34.217,64.078c26.574,3.768,84.781,26.672,105.105,82.215h125.001   c-14.686-59.25,17.115-72.73,43.827-60.756c21.455,9.618,40.715-2.11,42.403-12.509   C286.039,211.824,244.052,192.549,217.382,165.88z"/>
                <g>
                    <path style={{fill:'#0071CE'}} d="M165.745,233.867h-57c-4.142,0-7.5-3.358-7.5-7.5c0-4.142,3.358-7.5,7.5-7.5h57    c4.142,0,7.5,3.358,7.5,7.5C173.245,230.509,169.887,233.867,165.745,233.867z"/>
                    <path style={{fill:'#0071CE'}} d="M181.01,256.711h-57c-4.142,0-7.5-3.358-7.5-7.5c0-4.142,3.358-7.5,7.5-7.5h57    c4.142,0,7.5,3.358,7.5,7.5C188.51,253.353,185.152,256.711,181.01,256.711z"/>
                </g>
                <path style={{fill:'#00B3E3'}} d="M125.454,170.237c-27.161,0-50.142-20.985-85.661-20.985c-12.555,0-20.53,6.42-25.608,16.504   c30.033,5.918,64.411,35.633,108.864,33.708c24.479-1.06,57.639-33.992,93.814-34.09   C175.407,124.491,152.501,170.237,125.454,170.237z"/>
                <path style={{fill:'#E8E8E8'}} d="M429.608,289.846h-35.8c-66.146,0-265.817-16.922-326.298-16.922H14.32   c-8.438,0-14.32,6.905-14.32,15.344v32.215c0,8.438,5.882,15.343,14.32,15.343h415.287c10.127,0,18.412-8.286,18.412-18.412v-9.156   C448.02,298.131,439.734,289.846,429.608,289.846z"/>
                <path style={{fill:'#0071CE'}} d="M382.12,289.704c-29.316-0.629-74.753-3.306-122.727-6.345c2.492,7.871,3.945,15.984,1.783,21.016   c-2.956,6.879-16.243,20.591-27.504,31.451H376.47C380.882,322.236,384.199,305.694,382.12,289.704z"/>
                <path style={{fill:'#00B3E3'}} d="M99.177,311.875H48.51c-4.142,0-7.5-3.358-7.5-7.5c0-4.142,3.358-7.5,7.5-7.5h50.667   c4.142,0,7.5,3.358,7.5,7.5C106.677,308.517,103.319,311.875,99.177,311.875z"/>
                <circle style={{fill:'#00416A'}} cx="209.986" cy="185.568" r="7.5"/>
                <circle style={{fill:'#00416A'}} cx="232.673" cy="201.289" r="7.5"/>
                <circle style={{fill:'#00416A'}} cx="257.843" cy="214.945" r="7.501"/>
            </g>
            </svg>
            </InlineButtons.Item></Link>
			<Link to="/ranks" className="tablink"><InlineButtons.Item className={"tab " + (active == 2 ? "active" : "")} text="Leaderboard">
				<svg width="32" height="32" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<g id="SVGRepo_iconCarrier">
						<path d="M6.5,12h-4a.5.5,0,0,0-.5.5V20H7V12.5A.5.5,0,0,0,6.5,12Z"></path>
						<path d="M14,4H10a.5.5,0,0,0-.5.5V20h5V4.5A.5.5,0,0,0,14,4Z"></path>
						<path d="M21.5,8h-4a.5.5,0,0,0-.5.5V20h5V8.5A.5.5,0,0,0,21.5,8Z"></path>
					</g>
				</svg>
			</InlineButtons.Item></Link>
			<Link to="/camera?task=jj" reloadDocument className="tablink"><InlineButtons.Item className={"tab " + (active == 3 ? "active" : "")} text="Jumping Jacks"
			>
				<img src="/jumping-jack.png" style={{"width":"32px"}}/>
			</InlineButtons.Item></Link>
            <Link to="/camera?task=squat" reloadDocument className="tablink"><InlineButtons.Item className={"tab " + (active == 4 ? "active" : "")} text="Squat"
			>
				<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
					<path fill="currentColor" d="M 10.96875 2 C 9.8642193 2.0092535 8.9907465 2.9267193 9 4.03125 C 9.0092535 5.1357807 9.9267193 6.0092535 11.03125 6 C 12.13578 5.9907465 13.009254 5.0732807 13 3.96875 C 12.990746 2.8642193 12.07328 1.9907465 10.96875 2 z M 9.75 7 C 9.013 7 8.348 7.4125 8 8.0625 C 8 8.0625 6.802 12.34525 6.375 13.40625 C 5.993 14.35625 6.14375 15.5565 6.96875 16.0625 C 7.70175 16.5125 11.40875 16.9115 12.46875 17.0625 L 9.5 22 L 12 22 L 14.6875 17.4375 C 14.9235 16.7945 15.00675 16.07725 14.59375 15.53125 C 14.21275 15.02825 13.435 14.7945 13 14.6875 C 12.318 14.5185 10.951 14.206 10 14 L 11.5625 9 L 18 9 L 18 7 L 9.75 7 z" />
				</svg>
			</InlineButtons.Item></Link>
		</InlineButtons>
        </>
}

export default Maintabs;